import React, { useState, useEffect } from "react";
import "./Contact.css";
const Contact = () => {
  const [success, setSuccess] = useState(false);
  const [time, setTime] = useState(0);

  const sub = async (e) => {
    const scriptURL =
      "https://script.google.com/macros/s/AKfycbzKZVZCVRAIcr-b0QA9nWtGjfxCUZxNOa9VzBaY6uo3mC7EmHGQJX_AvlCOdWEG-C59/exec";
    const form = document.forms["submit-to-google-sheet"];
    const msg = document.getElementById("msg");
    e.preventDefault();
    console.log("h");
    await fetch(scriptURL, { method: "POST", body: new FormData(form) })
      .then(
        async (response) => (msg.innerHTML = "Message Sent Successfully"),
        setTimeout(() => {
          msg.innerHTML = "";
        }, 5000),
        form.reset()
      )
      .catch(
        (error) => console.error("Error!", error.message),
        setSuccess(false)
      );
  };

  return (
    <div id="contact">
      <div className="container">
        <div className="row">
          <div className="contact-left">
            <h1 className="sub-title">Contact me</h1>
            <p>
              <span>Email: </span>
              <a href="mailto:mohakvaswani@gmail.com">mohakvaswani@gmail.com</a>
            </p>
            <p>
              <span>Phone: </span>+1(858)-214-4534
            </p>
            <div className="social-icons">
              <a href="https://github.com/mohakvni">
                <img src="./Github-inv.svg"></img>
              </a>
              <a href="http://www.linkedin.com/in/movaswani">
                <img src="./LinkedIn.svg"></img>
              </a>
            </div>
            <a href="./Resume - Mohak Vaswani - F.pdf" download className="btn">
              Download CV
            </a>
          </div>
          <div className="contact-right">
            <form name="submit-to-google-sheet" onSubmit={sub}>
              <input
                type={"text"}
                name="Name"
                placeholder="Your Name"
                required
              ></input>
              <input
                type={"email"}
                name="Email"
                placeholder="Your Email"
              ></input>
              <textarea
                name="Message"
                rows={6}
                placeholder="Your Message"
              ></textarea>
              <button type="submit" className="btn" id="btn2">
                Submit
              </button>
            </form>
            <span id="msg"></span>
          </div>
        </div>
      </div>
      <div className="copyright">Made by Mohak Vaswani, using React.Js</div>
    </div>
  );
};

export default Contact;
