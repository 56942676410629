import React from "react";
import "./Work.css";

export const Work = () => {
  return (
    <div id="services">
      <div className="container">
        <h1 className="sub-title">Work and Leadership Experience</h1>
        <div className="services-list">
          <div>
            <h2>UC San Diego Information and Technology Services</h2>
            <h4>Software Engineering Intern (Full Stack)</h4>
            <p>
              Streamlined comparisons between the Degree Audit System and QA
              database using Java, Spring, JSP, SQL, XML. Developed a strong
              understanding of object-oriented programming, MVC architecture,
              and software design.
            </p>
          </div>
          <div>
            <h2>Triton Software Engineering, UC San Diego</h2>
            <h4>Full Stack Developer</h4>
            <p>
              Assisted development of a volunteer hub internal tool for
              Make-a-wish foundation, San Diego using JS, React.js, Node.js,
              Express.js, Mongoose, Multer, Amazon-SDK, and Axios. Utilized
              iterative-development techniques with set code-infrastructure to
              develop and style the Homepage and ContactUs Page, and integrate
              it with backend through API calls using Axios.
            </p>
          </div>
          <div>
            <h2>Triton Software Engineering, UC San Diego</h2>
            <h4>Product Manager</h4>
            <p>
              Led weekly client meetings to discuss progress and elicit feedback
              to prioritize features for the next release. Worked with
              engineering managers, developers, and designers to find effective
              software solutions to problems.
            </p>
          </div>
          <div>
            <h2>ACM AI, UC San Diego</h2>
            <h4>Full Stack Software Developer</h4>
            <p>
              Contributed to the development of a competition platform that is
              to be used by over 1000 potential competitors using python,
              JavaScript, TypeScript, and React.Js. Improved the UI on the
              frontend and debugging through keyboard input.{" "}
            </p>
          </div>
          <div>
            <h2>Indian Students Association, UC San Diego</h2>
            <h4> Vice-President</h4>
            <p>
              Organized Events and meetups for the vast Indian community at UC
              San Diego.
            </p>
          </div>
          <div>
            <h2>IEEE @ UC San Diego</h2>
            <h4>Technical Chair</h4>
            <p>
              Collaborated in a team of 3 to host technical workshops on various
              fields in the computer science, mechanical engineering, and
              electrical engineering domain.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
