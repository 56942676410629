import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-scroll";

const Header = () => {
  const [isOpen, setOpen] = useState(false);

  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <div id="header">
      <div className="container">
        <nav>
          <img src="./logo.png" className="logo"></img>
          <ul style={isOpen ? { right: "0px" } : { right: "-200px" }}>
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a>
                <Link to="about" smooth={true}>
                  {" "}
                  About{" "}
                </Link>
              </a>
            </li>
            <li>
              <a>
                <Link to="services" smooth={true}>
                  {" "}
                  Work Experience{" "}
                </Link>
              </a>
            </li>
            <li>
              <a>
                <Link to="projects" smooth={true}>
                  {" "}
                  Projects{" "}
                </Link>
              </a>
            </li>
            <li>
              <a>
                <Link to="contact" smooth={true}>
                  {" "}
                  Contact{" "}
                </Link>
              </a>
            </li>
            <img
              src="Close.svg"
              className="img2"
              onClick={() => closeMenu()}
            ></img>
          </ul>
          <img src="Menu.svg" className="img2" onClick={() => openMenu()}></img>
        </nav>
        <div className="header-text">
          <p>Web Development / System Architecture</p>
          <h1>
            Hi, I'm <span>Mohak Vaswani</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Header;
