import React, { useState } from "react";
import "./About.css";

const About = () => {
  const [tab, setTab] = useState(0);

  const openTab = (tabName) => {
    setTab(tabName);
  };

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="about-col-1">
            <img src="./Mohak.jpg"></img>
          </div>
          <div className="about-col-2">
            <h1 className="sub-title">About Me</h1>
            <p>
              Hi there! I see that you have stubmled across my website. To give
              a brief introduction, my name is Mohak Vaswani. I am a Computer
              Science sophomore at University of California, San Diego. My
              interest lies in web-development and system architecture. I love
              taking up new challenges and exploring new domains. My impecabble
              thurst for knowledge has always been a pioneer to all the projects
              I have and will pursue.
            </p>
            <div className="tab-titles">
              <p
                className={"tab-links" + (tab == 0 ? " active-link" : "")}
                onClick={() => openTab(0)}
              >
                Skills
              </p>
              <p
                className={"tab-links" + (tab == 1 ? " active-link" : "")}
                onClick={() => openTab(1)}
              >
                Experience
              </p>
              <p
                className={"tab-links" + (tab == 2 ? " active-link" : "")}
                onClick={() => openTab(2)}
              >
                Education
              </p>
            </div>
            <div className={"tab-contents" + (tab == 0 ? " active-tab" : "")}>
              <ul>
                <li>
                  <span>Technical:</span>
                  <br /> Python, HTML5, CSS, JavaScript, Java, C, TypeScript
                </li>
                <li>
                  <span>Frameworks and Libraries: </span>
                  <br /> Express.js, Node.js, React.js, Bootstrap, PyTorch,
                  openCV, Spring
                </li>
                <li>
                  <span>Databases & cloud</span>
                  <br />
                  SQL, MongoDB, AWS
                </li>
                <li>
                  <span>Tools:</span>
                  <br />
                  Matlab, Jupyter notebooks, Git, Vim
                </li>
              </ul>
            </div>
            <div className={"tab-contents" + (tab == 1 ? " active-tab" : "")}>
              <ul>
                <li>
                  <span>July 2022 - Present</span>
                  <br />
                  UC San Diego - Information and Technology Services - Full
                  Stack SWE Intern
                </li>
                <li>
                  <span>May 2022 - Present</span>
                  <br />
                  Triton Software Engineering, UCSD - Product Manager
                </li>
                <li>
                  <span>November 2021 - Present</span>
                  <br />
                  Triton Software Engineering, UC San Diego - Full Stack
                  Software Developer
                </li>
                <li>
                  <span>May 2022 - Present</span>
                  <br />
                  ACM-AI, UC San Diego - Full Stack Software Developer
                </li>
              </ul>
            </div>
            <div className={"tab-contents" + (tab == 2 ? " active-tab" : "")}>
              <ul>
                <li>
                  <span>Fall 2021 - June 2024 (expected)</span>
                  <br />
                  UC San Diego - Bachelor's of Science in Computer Science
                  <br /> GPA: 4.00
                </li>
                <li>
                  <span>Spring 2008 - Summer 2021</span>
                  <br />
                  DPS - Modern Indian School, Doha Qatar
                  <br /> GPA: 4.00
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
