import React from "react";
import "./Projects.css";

const Projects = () => {
  return (
    <div id="projects">
      <div className="container">
        <h1 className="sub-title">My Projects</h1>
        <div className="work-list">
          <div className="work">
            <img src="./JavaScript.png"></img>
            <div className="layer">
              <h3>Volunteer Hub - Make A Wish San Diego</h3>
              <p>
                Assisted development of a volunteer hub internal tool for
                Make-a-wish foundation, San Diego using JS, React.js, Node.js,
                Express.js, Mongoose, Multer, Amazon-SDK, and Axios.
              </p>
              <a href="https://github.com/TritonSE/MAW-Volunteer-Hub">
                <img src="./Github.svg" className="github"></img>
              </a>
            </div>
          </div>
          <div className="work">
            <img src="./Python.png"></img>
            <div className="layer">
              <h3>CSES Quarterly projects - TriDev</h3>
              <p>
                Lead a team of 4 to develop a discord bot to detect and add
                songs of similar music features using a K-means clustering
                algorithm using Python, pandas, scikit-learn, numpy, and
                matplotlib
              </p>
              <a href="https://github.com/mohakvni/CSES-Project-SP22">
                <img src="./Github.svg" className="github"></img>
              </a>
            </div>
          </div>
          <div className="work">
            <img src="./Python.png"></img>
            <div className="layer">
              <h3>ACM-AI Quarterly Projects, UCSD</h3>
              <p>
                Used reinforcement learning techniques to train a model to play
                flappy birds using Python, PyTorch, pandas, Open AI Gym, and
                Stable-Baselines3
              </p>
              <a href="https://github.com/acmucsd-projects/wi22-cheeze">
                <img src="./Github.svg" className="github"></img>
              </a>
            </div>
          </div>
          <div className="work">
            <img src="./Python.png"></img>
            <div className="layer">
              <h3>RoseHack Hackathon 2022, UCR</h3>
              <p>
                Built an ML based software that detects emotions and suggests
                Spotify songs using Python, TensorFlow, Tkinter, CSV, Spotify
                API, openCV, Requests modules
              </p>
            </div>
          </div>
          <div className="work">
            <img src="./Python.png"></img>
            <div className="layer">
              <h3>ATAPP (Attendance App.)</h3>
              <p>
                Lead a team of 3 to create a CLI application that records
                attendance from online meetings. Developed a Google forms-based
                application with a backend service to migrate data onto a local
                database and allows analytics using python-MySQL connectivity
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
