import React from "react";
import "./Home.css";
import Header from "./Header";
import About from "./About";
import Projects from "./Projects";
import Contact from "./Contact";
import { Work } from "./Work";

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const Home = () => {
  return (
    <div>
      <body>
        <div data-aos="fade-down" data-aos-delay="50">
          <Header />
        </div>
        <div data-aos="fade-left" data-aos-delay="50">
          <About />
        </div>
        <div data-aos="fade-right" data-aos-delay="50">
          <Work />
        </div>
        <div data-aos="fade-left" data-aos-delay="50">
          <Projects />
        </div>
        <div data-aos="fade-right" data-aos-delay="50">
          <Contact />
        </div>
      </body>
    </div>
  );
};

export default Home;
